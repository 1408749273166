#block-block-7 ul {
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		font-size: 0;
		a {
			display: inline-block;
			height: 28.5px;
			width: 28.5px;
			background: url(../images/social.png) 0 -28.5px no-repeat;
			&:hover {
				background-position: 0px 0;
			}

			&.tw {
				background-position: -85.5px -28.5px;
				&:hover {
					background-position: -85.5px 0;
				}
			}
			&.gp {
				background-position: -28.5px -28.5px;
				&:hover {
					background-position: -28.5px 0;
				}
			}
			&.pi {
				background-position: -57px -28.5px;
				&:hover {
					background-position: -57px 0;
				}
			}
		}
	}
}

.footer {
	border: none;
	margin-top: 0;

	ul, li, a {
		color: @text-grey;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
		}
	}
}

.region-footer-fifth {
	img {
		width: 100%;
	}
}

.copyright {
	padding: 5px 0;
	background-color: @sepia;
}

#block-block-12 {
	background: #000;
	color: #fff;
	padding: 20px;
	font-size: 20px;

	.btn-success {
		margin-left: 15px !important;
		color: #000 !important;
		background-color: #fff !important;
		border-color: #fff !important;
	}
}