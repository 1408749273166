@media (min-width: 1200px) {
	.container {
	    width: 1400px;
	}
	.navbar.container {
	    max-width: 1400px;
	    //padding-left: 0px;
	    //padding-right: 0px;
	}
}

.menu-block-1 {
	.nav > li > a {
		padding: 5px 7px;
		color: @grey;
	}
	.sub-menu {
		display: none;
	}

	li.active {
		.sub-menu {
			display: block;
		}
	}
}

#footer-region-wrapper {
	margin-top: 15px;
	border-top: 3px solid @pink;
}

#block-views-exp-search-page {
	.views-exposed-widgets {
		margin-bottom: 0;
		margin-top: 24px;

		.views-exposed-widget {
			padding: 0;
		}
	}
}
#prefooter {
	margin-top: 15px;
	div {
		color: @light-grey;
	}
}

ol.breadcrumb {
	border-top: 3px solid @pink;
}

.field .container {
	padding: 0;
}

#commerce-wishlist-share {
	select {
		max-width: 400px;
	}
}

.views-field-wishlist-add-to-cart {
	.form-item-quantity input {
		max-width: 250px;
	}
}

.view-blog {
	.views-row {
		border-top: 2px solid #ffb9bf;
	}
	.pane-node-field-date {
		background: #ffb9bf;
		padding: 10px;
		font-weight: bold;
		color: #fff;
		font-size: 23px;
		text-align: center;
	}
	.pane-node-body {
		padding-top: 3px;
		margin-bottom: 20px;
	}
}

.node-article {
	.pane-node-field-date {
		font-weight: bold;
		.pane-content {
			display: inline-block;
		}
		margin-bottom: 10px;
	}
}

a.btn-success {
	color: #fff !important;
	background-color: #5cb85c !important;
	border-color: #4cae4c !important;
	background-image: none !important;
	border: 1px solid transparent !important;
	white-space: nowrap !important;
	padding: 6px 12px !important;
	font-size: 14px !important;
	line-height: 1.42857143 !important;
	border-radius: 4px !important;
}

@media  (max-width: 1024px) {
  footer {
  	.block-title {
  		font-size: 22px;
  	}
  }
}

@media  (max-width: 767px) {
  #block-superfish-1 {
  	display: none;
  }

  #mobile-menu-trigger {
  	font-size: 22px;
  }
  .helpline {
  	font-size: 15px !important;
  }

  .menu-block-1 {
  	display: none;
  }
}

#mmenu_left {
	#block-user-login {
		padding: 25px;
	}
}