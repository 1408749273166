.node-product-display {
  &.node-teaser {
    .field-name-title-field a {
      font-size: 15px;
      color: #222;
      text-decoration: none;
    }

    .bootstrap-twocol-stacked {
      .row {
        text-align: center;
      }

      .pane-node-product-field-original-price,
      .pane-node-product-commerce-price {
        display: inline-block;
      }

      .pane-node-product-field-original-price {
        font-size: 11px;
        text-decoration: line-through;
      }
    }

    h2 {
      line-height: 18px;
    }
  }

  &.node-full {
    .pane-node-product-commerce-price {
      margin-bottom: 0 !important;
    }

    .pane-node-field-product {
      margin-top: 20px;
    }

    .pane-node-product-field-original-price {
      text-decoration: line-through;
    }
  }
}

.cloud-zoom-gallery-thumbs {
  margin-top: 10px;
}

.form-item-quantity {
  .ui-spinner {
    display: none;
  }
}

input[name="quantity"] {
  margin-right: 0;
}

.cloud-zoom-container #wrap {
  z-index: 10 !important;
}

.form-type-commerce-fancy-attributes .option.radio {
  display: block !important;
  font-size: 0;
  position: absolute;
}

.commerce-fancy-attributes-color {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border-color: #555;
}

.form-type-commerce-fancy-attributes-ajax .form-item {
  width: auto;
  display: inline-block;
  margin: 0 10px 0 0;
}

.form-type-commerce-fancy-attributes-ajax .form-item label {
  display: none;
  position: absolute;
  top: -25px;
  left: 40px;
  div {
    font-size: 12px;
  }
}

.form-type-commerce-fancy-attributes-ajax .help-block {
  cursor: pointer;
}

.commerce-add-to-cart select,
.commerce-add-to-cart input[type="text"] {
  width: 300px !important;
  max-width: 300px !important;
}

div.messages.commerce-add-to-cart-confirmation .message-inner .button-wrapper {
  padding: 40px 10px;
}

div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content {
  width: 75%;
}

.view-confirm-message-product-display {
  .commerce-product-sku,
  .field-name-field-product-color,
  .field-name-field-size {
    color: #222;
    .commerce-product-sku-label,
    .field-label {
      color: #222 !important;
      font-weight: normal !important;
      font-size: 14px !important;
    }
  }

  .views-field-quantity, .views-label-quantity {
    color: #222 !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
}

div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .content .field-name-field-images img {
  border: none;
}

div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .views-field-commerce-total {
  margin-top: 0;
  padding: 25px 0;
}

.node-product-display.node-full {
  .field-name-field-item-number {
    color: @light-grey;
    font-weight: bold;
    .field-label {
      float: left;
    }
  }

  .pane-node-product-commerce-price {
    margin: 20px 0;
    font-size: 20px;
  }

  .form-item-attributes-field-product-color {
    margin-bottom: 0;
  }

  .form-item-attributes-field-product-color,
  .form-item-attributes-field-size {
    label {
      text-transform: uppercase;
    }
  }

  .form-item-quantity {
    input {
      width: 90px !important;
    }
  }
}

.pane-maternity-product-details-accordion {
  margin: 15px 0;
  .panel {
    border-radius: 0;
  }
  .panel-heading {
    padding: 0 !important;
    background: #e5e5e5;
    border: none;
    border-radius: 0;

    h4 {
      a {
        display: block;
        padding: 10px 15px !important;
      }
    }
  }
}

.cloud-zoom-gallery-thumbs {
  clear: none !important;
  margin-left: 10px;
  a {
    display: block;
    margin-bottom: 7px;
  }
}

.commerce-add-to-cart select, .commerce-add-to-cart input[type="text"] {
  width: 130px !important;
}

.view-shop-default {
  .shop-row {
    margin-bottom: 20px;
  }
}

.commerce-add-to-cart {
  button {
    background: @light-pink;
    border: 1px solid @pink;
    color: #000;
    width: 250px;
    border-radius: 0 !important;
    padding: 15px 0;
    font-size: 18px;
    font-weight: bold;

    &:hover, &:focus, &:active, &:focus:active {
      background: @light-pink;
      border: 1px solid @pink;
      color: #000;
      background: @pink;
    }
  }
  button[name="op"] {
    margin-right: 50px;
  }
}

#block-block-9 {
  margin-bottom: 20px;
}

.node-type-gift-certificate .form-item-quantity {
  display: none;
}

@media (max-width: 767px) {
  form.commerce-add-to-cart {
    button[type="submit"] {
      float: left;
      width: 49%;
      margin: 0 0.5%;
    }
  }

  .cloud-zoom-container {
    #wrap, .cloud-zoom-gallery-thumbs {
      float: none !important;
    }
  }
  .cloud-zoom-gallery-thumbs {
    margin-left: 0px;
  }
  .cloud-zoom-gallery-thumbs a {
    display: inline-block !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
  }
}