#webform-client-form-46 {
	margin-top: 20px;

	input, textarea {
		max-width: 600px;
	}
	.grippie {
		display: none !important;
	}
}
