#superfish-1 {
	margin-top: 30px;
}

.sf-menu > li {
	margin-right: 20px !important;
	line-height: 35px;
	a {
		color: #2c2c2c;
	}
}

.sf-menu.sf-horizontal.sf-shadow ul, 
.sf-menu.sf-vertical.sf-shadow ul, .sf-menu.sf-navbar.sf-shadow ul ul {    
	background: #fff;
    border-radius: 0 !important;
    padding: 0 !important;
    border: 2px solid #fde3e7;
    border-radius: 3px !important;
    padding: 0px 10px !important;
    border-top: 4px solid #fde3e7;
}

.sf-menu > li > ul > li > ul {
	margin-left: 14px !important;
}

ul.sf-menu ul {
  overflow: visible !important;
}


@media (max-width: 767px) {
	.menu-block-2 {
		display: none !important;
	}
}
