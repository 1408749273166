#edit-commerce-coupon,
#edit-customer-profile-billing,
#edit-customer-profile-shipping {
	margin-bottom: 20px;
}

.commerce-checkout-progress {
	padding-left: 0;

	li {
		text-align: center !important;
		list-style-position: inside !important;
		width: 20% !important;
	}
}

.commerce-add-to-cart-confirmation {
	.added-product-title {
		width: 332px;
	}
	.commerce-product-sku {
		clear: both;
		margin-left: 20px;
	}
	.img-responsive {
		max-width: 322px;
	}
	.field-name-field-product-color,
	.field-name-field-size,
	.views-field-quantity,
	.views-field-commerce-total {
		margin-left: 20px;
	}
}

@media (max-width: 400px) {
	div.messages.commerce-add-to-cart-confirmation {
		width: 92% !important;
		left: auto !important;
		margin: 0 auto !important;
		top: -25px;

		img {
			max-width: 100% !important;
		}

		.message-inner .button-wrapper {
			width: 28% !important;
		}
	}

	.pane-maternity-product-details-accordion {
		clear: both;

	}
	form.commerce-add-to-cart button[type="submit"] {
		float: none;
		display: inline-block;
		width: 48%;
	}

	.node-product-display .panel-panel.right {
		clear: both;
	}
}

.checkout-continue {
	background-color: #5cb85c;
	color: #fff;
}

.commerce-paypal-icon[title="American Express"],
.commerce-paypal-icon[title="Discover"],
.commerce-paypal-icon[title="eCheck"] {
	display: none;
}

.page-cart .commerce_coupon {
	float: right;

	.form-item-coupon-code {
		width: 300px;
		display: inline-block;
		margin-right: 11px;
	}
}

#edit-commerce-payment {
	.form-item-commerce-payment-payment-details-credit-card-number,
	.form-item-commerce-payment-payment-details-credit-card-code{
		max-width: 300px;
	}
	.commerce-credit-card-expiration {
		label {
			display: block;
		}
		.form-item select {
			max-width: 145px;
			display: inline-block;
		}
	}
}

@media (max-width: 767px) {
	.commerce-line-item-views-form {
		table, tbody, tr {
			display: block;
		}

		td.cart-product-details {
			display: block;
		}
	}

	.commerce-order-handler-area-order-total .commerce-price-formatted-components {
		float: right !important;
		width: auto !important;
	}

	.view-commerce-cart-summary {
		table, tbody, tr {
			display: block;
		}
		thead {
			display: none;
		}
	}
}
@media (max-width: 400px) {
	.view-commerce-cart-summary {
		table, tbody, tr {
			display: block;
		}
		thead {
			display: none;
		}
		tbody tr td {
			white-space: normal !important;
		}

		tbody tr td {
			display: block;
			width: 100%;
		}
	}

	.checkout_review, .view-commerce-cart-summary {
		.commerce-price-formatted-components {
			width: 100% !important;
		}
	}
}