#flexslider-1 {
	margin: 0;
}

.flex-control-nav {
	display: none;
}

.pane-bean-free-shipping-on-all-orders-over,
.pane-bean-easy-payment-and-return,
.pane-bean-24-7-customer-support {
	h2 {
		font-size: 25px;
		text-align: center;
	}
}

.pane-block-11 {
	width: 70%;
	margin: 20px auto;
	.pane-content {
		font-size: 12px;
		line-height: 13.8px;
		text-align: justify;
	}
}

.pane-block-14 {
	background: #000;
	color: #fff;
	padding: 10px;
	font-size: 20px;
	text-align: center;
}