header#navbar {
	background: none;
	border: none;
	border-bottom: 1px solid #f9eef0;
    border-radius: 0;
    box-shadow: 1px 1px 6px #f9eef0;
    margin-top: 3px;
}

.navbar .logo {
	margin: 0;

	img {
		height: 64px;
	}
}

#topheader {
	background: #f3f3f3;
	padding: 5px 0;
	color: #333;

	a {
		color: #333;
		//display: inline-block !important;
		&:hover {
			color: #333;
			text-decoration: none;
		}
	}

	.text-right a {
		display: inline-block !important;
	}

	#show-cart {
		margin-right: 10px;
	}
}

#block-commerce-cart-cart {
	display: none;
	width: 350px;
    z-index: 1000;
    position: absolute;
    right: 0;

	background: #fff;
    border-radius: 0 !important;
    padding: 0 !important;
    border: 2px solid #fde3e7;
    border-radius: 3px !important;
    padding: 0px 10px !important;
    border-top: 4px solid #fde3e7;
    box-shadow: 2px 2px 6px #ddd;
    top: 30px;
}

.appear {
	opacity: 1;
	display: block !important;
	transition: opacity 1s linear;
}

.helpline {
	font-size: 18px;
	font-weight: bold;	
}